import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/licenses');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/license', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/license/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async updateStateClient(data,status) {
        try {
            const response = await axios.post(`/license/updateStatusClient`, {corporate:data.id,status:status});
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
    ,async delete(data) {
        try {
            const response = await axios.put(`/license/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
