<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="'Office'"
        prop="office"
        :rules="[{ required: true, message: 'Office is required' }]"
      >
        <el-select
          class="w-100"
          v-model="form.office"
          placeholder="Select Office"
          @change="getUsersOffice"
        >
          <el-option
            v-for="item in offices"
            :key="item.id"
            :label="item.office_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="'Responsible'"
        prop="responsible"
        :rules="[{ required: true, message: 'Responsible is required' }]"
      >
        <el-select
          class="w-100"
          v-model="form.responsible"
          placeholder="Select Responsible"
        >
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.user.name"
            :value="item.user.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="'Client'"
        prop="corporate"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <v-select
          class="style-chooser w-100"
          placeholder="Select Company"
          :options="corporates"
          label="name"
          :reduce="(corporate) => corporate.uuid"
          v-model="form.corporate"
        ></v-select>
      </el-form-item>
      <el-form-item
        :label="$t('license.licensetype')"
        prop="license"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-select
          class="w-100"
          v-model="form.license"
          placeholder="Select license"
        >
          <el-option
            v-for="item in licenseTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="row">
        <div class="col-6">
          <el-form-item
            :label="$t('license.serial')"
            prop="serial"
            :rules="[{ required: true, message: 'The field is required' }]"
          >
            <el-input type="text" v-model="form.serial"></el-input>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item :label="$t('license.pin')" prop="pin">
            <el-input type="text" v-model="form.pin"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <el-form-item
            :label="$t('license.issuedate')"
            prop="issue"
            :rules="[{ required: true, message: 'The field is required' }]"
          >
            <el-date-picker
              class="w-100"
              v-model="form.issue"
              type="date"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item
            :label="$t('license.duedate')"
            prop="due"
            :rules="[{ required: true, message: 'The field is required' }]"
          >
            <el-date-picker
              class="w-100"
              v-model="form.due"
              type="date"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
            ></el-date-picker>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <el-form-item
            :label="$t('license.priordaysremainder')"
            prop="priorDaysRemainder"
          >
            <el-input
              type="number"
              v-model="form.priorDaysRemainder"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item :label="$t('license.status')" prop="status">
            <el-select
              v-model="form.status"
              placeholder="Select Status"
              class="w-100"
            >
              <el-option :value="1" label="Current">Current</el-option>
              <el-option :value="0" label="Inactive">Expired</el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="row ml-7">
        <div>
          <p class="float-left mr-1">url:</p>
          <a :href="form.web" v-if="form.web != null" target="_blank">{{
            form.web
          }}</a>
        </div>
      </div>
      <el-form-item :label="$t('license.web')" prop="web">
        <el-input type="text" v-model="form.web"></el-input>
      </el-form-item>
      <el-form-item :label="$t('license.forwarder')" prop="forwarder">
        <el-input type="text" v-model="form.forwarder"></el-input>
      </el-form-item>
      <div class="row">
        <div class="col-6">
          <el-form-item :label="$t('license.user')" prop="user">
            <el-input type="text" v-model="form.user"></el-input>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item :label="$t('license.password')" prop="password">
            <el-input type="text" v-model="form.password"></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item :label="$t('license.observation')" prop="observation">
        <el-input
          type="textarea"
          v-model="form.observation"
          :rows="5"
        ></el-input>
      </el-form-item>

      <el-button
        type="primary"
        @click="!data ? submitForm('form') : updateForm('form')"
        >Save License</el-button
      >
    </el-form>
  </div>
</template>

<script>
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import corporate from "@/services/api/corporate";
import licenseType from "@/services/api/licenseType";
import license from "@/services/api/license";

export default {
  props: ["data"],
  data() {
    return {
      offices: [],
      users: [],
      corporates: [],
      licenseTypes: [],
      form: {
        id: null,
        office: null,
        responsible: null,
        corporate: null,
        license: null,
        serial: null,
        pin: null,
        issue: null,
        due: null,
        priorDaysRemainder: null,
        status: null,
        web: null,
        forwarder: null,
        user: null,
        password: null,
        observation: null,
      },
    };
  },
  mounted() {
    licenseType.get().then((response) => {
      this.licenseTypes = response;
    });
    switch (this.$store.getters.discriminator) {
      case "administrator":
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
    }
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.office = this.data.corporate.office.id;
      this.getUsersOffice();
      this.form.corporate = this.data.corporate.uuid;
      this.form.responsible = this.data.responsible.id;
      this.form.license = this.data.licenseTypeId;
      this.form.serial = this.data.serial;
      this.form.pin = this.data.pin;
      this.form.issue = this.data.issueDate;
      this.form.due = this.data.dueDate;
      this.form.priorDaysRemainder = this.data.priorDaysRemainder;
      this.form.status = this.data.status;
      this.form.web = this.data.web;
      this.form.forwarder = this.data.forwarder;
      this.form.user = this.data.user;
      this.form.password = this.data.password;
      this.form.observation = this.data.observation;
    }
  },
  methods: {
    getUsersOffice() {
      this.form.corporate = null;
      this.form.responsible = null;
      corporate.getClientsByOffice(this.form.office).then((response) => {
        this.corporates = response;
      });
      officeUser.getUsersOffice(this.form.office).then((response) => {
        this.users = response;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          license
            .create(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          license
            .update(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 45px;
}
.style-chooser .vs__dropdown-menu {
  max-height: 150px;
}
</style>

