import { render, staticRenderFns } from "./License.vue?vue&type=template&id=cb3b94be&"
import script from "./License.vue?vue&type=script&lang=js&"
export * from "./License.vue?vue&type=script&lang=js&"
import style1 from "./License.vue?vue&type=style&index=1&id=cb3b94be&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports