import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/corporates');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getClientsByOffice(id) {
        try {
            const response = await axios.get('/corporate/'+id);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getStatsSumary(data) {
        try {
            const response = await axios.post('/corporate/business/stats', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getChartClientSumary(data) {
        try {
            const response = await axios.post('/corporate/business/chart', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getPdf(data) {
        try {
            const response = await axios.get(`/corporate/pdf/${data}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/corporate', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/corporate/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/corporate/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
